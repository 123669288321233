<template>
  <div>
    <Row
      :gutter="8"
      class="p-b-5"
    >
      <i-col span="4">
        <DatePicker
          size="small"
          v-model="publishDay"
          type="date"
          :clearable="false"
          style="width:100%"
          @on-change="handleSearch"
        ></DatePicker>
      </i-col>
      <i-col span="4">
        <Select
          v-model="query.assetId"
          size="small"
          placeholder="线路"
          clearable
          @on-change="handleChangeAsset"
        >
          <Option
            v-for="item in companyAssetArray"
            :key="'asset_'+item.id"
            :value="item.id"
          >{{item.name}}</Option>
        </Select>
      </i-col>
      <i-col span="4">
        <Select
          v-model="query.stationId"
          size="small"
          placeholder="站点"
          clearable
        >
          <Option
            v-for="item in stationArray"
            :key="'station_'+item.id"
            :value="item.id"
          >{{item.name}}</Option>
        </Select>
      </i-col>
      <i-col span="6">
        <Input
          type="text"
          size="small"
          placeholder="关键词（资源编号、站点编号、站点名称）"
        />
      </i-col>
      <i-col span="4">
        <Button
          icon="ios-search"
          size="small"
          type="primary"
          @click="handleSearch"
        >搜索</Button>

      </i-col>
    </Row>
    <Row class="p-b-5">
      <i-col span="4">
        <Button
          type="primary"
          size="small"
          class="m-r-5"
          :disabled="btnApprovalDisabled"
          @click="handleApproval"
        >发起审批</Button>
      </i-col>
      <!-- <i-col span="12">
        <Button
          size="small"
          type="warning"
        >批量暂停</Button>
        <Button
          size="small"
          class="m-l-5"
          type="success"
        >批量播放</Button>
      </i-col> -->
      <!-- <i-col
        span="12"
        class="text-right"
      >
        显示全部设备：
        <i-switch
          size="large"
          true-color="#13ce66"
        >
          <span slot="open">开启</span>
          <span slot="close">关闭</span>
        </i-switch>
      </i-col> -->
    </Row>

    <Table
      stripe
      size="small"
      :data="list"
      :columns="dataColumns"
      @on-selection-change="handleSelection"
    ></Table>
    <div class="paging_style">
      <Page
        size="small"
        :total="total"
        :page-size="query.pageSize"
        show-total
        show-elevator
        :current="query.pageNum"
        @on-change="handleChangePage"
      ></Page>
    </div>
    <set-duration-modal
      ref="setDuration"
      :operationType="1"
      :programId="selectedProgramId"
      :successFun="getListData"
    ></set-duration-modal>
  </div>
</template>

<script>
import { GetDateStr } from '@/utils/dateFormat'
import SetDurationModal from '../common/SetDurationModal.vue'

import { sysMixins } from '@/assets/mixins/sys'
import { companyMixin } from '@/assets/mixins/company'
import { commonMixins } from '@/assets/mixins/common'
import { eleArrangeMixins } from '../../mixins/index'

// import { getStationByAssetIds } from '@/api/product/station'
import { getRimStationByAssetIds } from '@/api/rim/station'
import { listPrograms, startProgramExamine } from '@/api/inventory/editing'

export default {
  mixins: [sysMixins, companyMixin, commonMixins, eleArrangeMixins],
  components: {
    SetDurationModal
  },
  data () {
    return ({
      tagType: 1,
      // formatMaxDate: {
      //   disabledDate (date) {
      //     return date.valueOf() > Date.now() + 86400000
      //   }
      // },
      publishDay: GetDateStr(Date.now(), 1),
      assetArray: [],
      stationArray: [],
      companyId: this.$store.getters.token.userInfo.companyId,
      query: {
        pageNum: 1,
        pageSize: 15,
        publishDay: '',
        publisherId: this.$store.getters.token.userInfo.publisherId,
        assetId: null,
        stationId: null,
        resourceCode: ''
      },
      list: [],
      total: 0,
      dataColumns: [
        { type: 'selection', width: 60, align: 'center' },
        { title: '资产', key: 'assetName' },
        { title: '站点', key: 'stationName' },
        { title: '资源编号', key: 'resourceCode' },
        {
          title: '起止时间',
          align: 'center',
          render: (h, params) => {
            return h('span', `${params.row.startTime} - ${params.row.endTime}`)
          }
        },
        {
          title: '状态',
          minWidth: 80,
          align: 'center',
          render: (h, params) => {
            switch (params.row.status) {
              case 0:
                return h('Tag', {
                  props: { color: 'warning' }
                }, '未生成')
              case 1:
                return h('Tag', '草稿')
              case 2:
                return h('Tag', {
                  props: { color: 'primary' }
                }, '审批中')
              case 3:
                return h('Tag', {
                  props: { color: 'error' }
                }, '审批未通过')
              case 4:
                return h('Tag', {
                  props: { color: 'black' }
                }, '已完成')

              default:
                return h('Tag', '未知')
            }
          }
        },
        {
          title: '操作',
          align: 'center',
          width: 140,
          render: (h, params) => {
            const html = []
            if (params.row.status === 0) {
              html.push(h('a', {
                style: { marginRight: '5px' },
                on: {
                  click: () => {
                    this.selectedProgramId = params.row.id
                    this.handleCreateDetail()
                  }
                }
              }, '生成明细'))
            } else {
              html.push(h('a', {
                style: { marginRight: '5px' },
                on: {
                  click: () => {
                    this.handleViewDetial(params.row)
                  }
                }
              }, '详情'))
            }
            return h('div', html)
          }
        }
      ],
      selectedProgramId: null,
      selectedItemArray: [], // 选中的项
      tempSelectionArray: [] // 临时存储数据
    })
  },
  computed: {
    curTaskInfo () {
      return this.$store.state.electronic.curTaskInfo
    },
    btnApprovalDisabled () {
      return !this.selectedItemArray.length
    }
  },
  created () {
    // 设置头部路由标签
    this.setLinkTagArray()
    this.setLinkTagArray({ key: 'electronicIndex', value: '投放设备清单' })
    this.setLinkRouterMaps(['electronicIndex', () => { this.handleIndexPage() }])
    this.setActivedTagName('electronicIndex')
  },
  mounted () {
    // 如果存在搜索条件状态，则取之
    if (this.$store.state.electronic.indexSearchParams) {
      this.publishDay = this.$store.state.electronic.indexSearchParams.publishDay
      Object.assign(this.query, this.$store.state.electronic.indexSearchParams)
    }

    this.tempSelectionArray = []
    this.getCompanyAssetArray()
    this.getListData()
    this.$store.dispatch('getApporvalRecordData', this.query)
  },
  methods: {
    getListData () {
      const pDate = new Date(this.publishDay)
      this.query.publishDay = `${pDate.getFullYear()}-${(pDate.getMonth() + 1).toString().padStart(2, '0')}-${pDate.getDate().toString().padStart(2, '0')}`

      this.$store.commit('set_index_search_params', this.query)
      listPrograms(this.query).then(res => {
        if (res && !res.errcode) {
          this.list = res.list
          this.total = res.totalRow
          this.query.pageNum = res.pageNumber
          this.list.forEach(item => {
            item._checked = this.selectedItemArray.includes(item.id)
            // 草稿可勾选
            item._disabled = item.status !== 1
          })
        } else {
          this.list = []
          this.total = 0
          this.query.pageNum = 1
        }
      })
    },
    getStationData () {
      getRimStationByAssetIds({ assetIds: JSON.stringify([this.query.assetId]) }).then(res => {
        if (res && !res.errcode) {
          this.stationArray = res
        } else {
          this.stationArray = []
        }
      })
    },
    handleChangePage (curPage) {
      this.query.pageNum = curPage
      this.getListData()
    },
    handleSearch () {
      this.query.pageNum = 1
      this.tempSelectionArray = []
      this.getListData()
      // 获取右侧审批记录数据
      this.$store.dispatch('getApporvalRecordData', this.query)
    },
    handleChangeAsset () {
      if (this.query.assetId) {
        this.getStationData()
      } else {
        this.query.stationId = null
        this.stationArray = []
      }
    },
    handleSelection (selection) {
      this.selectedItemArray = []

      const curSelection = selection.map(x => x.id)
      this.handelPageSelections(this.tempSelectionArray, curSelection, this.query.pageNum)
      this.tempSelectionArray.forEach(item => {
        this.selectedItemArray = this.selectedItemArray.concat(item.existSelections)
      })
    },
    /**
     * 发起审批
     */
    handleApproval () {
      this.$Modal.confirm({
        title: '操作提示',
        content: '确定要发起审批？',
        onOk: () => {
          const postData = {
            programIds: this.selectedItemArray.toString(),
            publishDay: this.query.publishDay,
            publisherId: this.query.publisherId
          }
          startProgramExamine(postData).then(res => {
            if (res && res.errcode === 0) {
              this.getListData()
              // 获取右侧审批记录数据
              this.$store.dispatch('getApporvalRecordData', {})
              this.selectedItemArray = []
              this.tempSelectionArray = []
              this.$Notice.success({ desc: '操作成功' })
            }
          })
        }
      })
    },
    handleIndexPage () {
      this.setShowLeftBlock(true)
      this.setShowRightBlock(true)
      this.setShowMap(false)
      this.setBottomHeight('90%')
      this.setLeftComponent('Material')
      this.setBottomComponent('IndexTable')
      this.setRightComponent('ApprovalHistory')
    },
    /**
     * 生成明细弹窗
     */
    handleCreateDetail () {
      this.$refs.setDuration.initShow()
    }
    //   /**
    //  * 查看详情
    //  */
    //   handleViewDetial (params) {
    //     this.$store.commit('set_cur_task_info', params)
    //     this.handleDetailPage()
    //     // 设置头部路由标签
    //     // this.setLinkTagArray({ key: 'installList', value: '上刊设置列表', actived: true })
    //     this.setLinkRouterMaps(['electronicPlayList', () => { this.handleDetailPage() }])
    //   },
    //   /**
    //  * 处理页面显示
    //  */
    //   handleDetailPage () {
    //     this.setShowLeftBlock(true)
    //     this.setLeftComponent('Summary')
    //     this.setShowRightBlock(false)
    //     this.setRightComponent('')
    //     this.setBottomComponent('PlayList')
    //   }
  },
  destroyed () {
    // this.$store.commit('set_approval_record_data', {})
  }
}
</script>
